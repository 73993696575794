/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    function add_maps_script_tag() {
        var maps_api_key = 'AIzaSyBmu-ysHzJCndB3qNAyjNQA3D-k1-4uHEk';
        var maps_callback = 'document.initMap';
        var maps_api_url = 'https://maps.google.com/maps/api/js';
        var maps_script_tag = document.createElement('script');
        maps_script_tag.setAttribute('type', 'text/javascript');
        maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
        maps_script_tag.setAttribute('defer', '');
        maps_script_tag.setAttribute('async', '');
        document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
    }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        document.addEventListener('touchstart', function(e) {
          if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
             $(".hamburger").removeClass('is-active');
             $("#sideNav").removeClass('sideNav-open');
             $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        }, false);

        // Scroll to top button
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });


        // Testimonial Slider
        $('.testimonial-slider').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          nextArrow: '<span class="arrow-right"></span>',
          prevArrow: '<span class="arrow-left"></span>',
          responsive: [
           {
             breakpoint: 768,
             settings: {
               arrows: false,
               dots: true
             }
           }
         ]
        });


        // Testimonial Slider
        $('.featured-properties-slider').slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          nextArrow: '<span class="arrow-right"></span>',
          prevArrow: '<span class="arrow-left"></span>',
          appendArrows: '.featured-properties-section .slider-nav',
          appendDots: '.featured-properties-section .slider-nav',
          responsive: [
           {
             breakpoint: 1440,
             settings: {
               slidesToShow: 2,
             }
           },
           {
             breakpoint: 768,
             settings: {
               slidesToShow: 1,
             }
           }
         ]
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page




      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Property Post type
    'single_properties': {
      init: function() {


        // Property Listing Gallery

        $('#imageGallery').lightSlider({
          gallery:true,
          item:1,
          loop:false,
          thumbItem:6,
          slideMargin:0,
          enableDrag: false,
          currentPagerPosition:'left',
          onSliderLoad: function(el) {
              el.lightGallery({
                  selector: '#imageGallery .lslide'
              });
          }
        });


        document.initMap = function() {
            var map;
            function initialize_gmap(bound) {
            	var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

            	var isDraggable = w > 480 ? true : false;

            	var settings = {
            		zoom: 15,
            		center: bound.getCenter(),
            		scrollwheel: false,
            		draggable: isDraggable,
            		mapTypeControl: false,
            		mapTypeControlOptions: {
            			style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
            		},
            		navigationControl: false,
            		navigationControlOptions: {
            			style: google.maps.NavigationControlStyle.SMALL
            		},
            		mapTypeId: google.maps.MapTypeId.ROADMAP
            	};

            	map = new google.maps.Map(document.getElementById("map_canvas"), settings);

            	var center;

            	function calculateCenter() {
            		center = map.getCenter();
            	}
            	google.maps.event.addDomListener(map, 'idle', function() {
            		calculateCenter();
            	});
            	google.maps.event.addDomListener(window, 'resize', function() {
            		map.setCenter(center);
            	});
            }

            function addMarker(point_lat, point_long, address, title) {

            	var point = new google.maps.LatLng(point_lat, point_long);
                var infowindow = new google.maps.InfoWindow();
                var formattedAddress = address.split(' ').join('+');

            	var contentString = '<div id="gmapContent" style="text-align:left; padding: 10px;">' +
            		'<a href="https://www.google.com/maps/dir/?api=1&destination=' + formattedAddress + '" target="_blank"><h3>' + title + '</h3></a>' +
            		'<div id="bodyContent"><p>' + address + '</p>' +
                '<p style="margin: 0 0 5px;"><a href="https://www.google.com/maps/dir/?api=1&destination=' + formattedAddress + '" target="_blank">' +
                '<i class="fa fa-external-link"></i> Get Directions</a></p></div></div>';

            	var companyMarker = new google.maps.Marker({
            		position: point,
            		map: map,
            		title: title
            	});

              infowindow.setContent(contentString);
              infowindow.open(map, companyMarker);

            }

            var locations = [];

            $('.location-map').each(function() {
            	var location = [];
            	location.push(parseFloat($(this).find('.map-data').find('.lat').data('lat')));
            	location.push(parseFloat($(this).find('.map-data').find('.lng').data('lng')));
            	location.push($(this).find('.map-data').find('.address').data('address'));
            	location.push($(this).find('.map-data').find('.title').data('title'));
            	locations.push(location);
            });

            if ($('#map_canvas').length) {
            	var bound = new google.maps.LatLngBounds();
            	for (i = 0; i < locations.length; i++) {
            		bound.extend(new google.maps.LatLng(locations[i][0], locations[i][1]));
            	}
            	initialize_gmap(bound);
            	for (i = 0; i < locations.length; i++) {
            		addMarker(locations[i][0], locations[i][1], locations[i][2], locations[i][3]);
            	}
            }
        };
        add_maps_script_tag();

      }
    },
    'contact': {
      init: function() {
          document.initMap = function() {
            var map;
            function initialize_gmap(bound) {
            	var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

            	var isDraggable = w > 480 ? true : false;

            	var settings = {
            		zoom: 14,
            		center: bound.getCenter(),
            		scrollwheel: false,
            		draggable: isDraggable,
            		mapTypeControl: false,
            		mapTypeControlOptions: {
            			style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
            		},
            		navigationControl: false,
            		navigationControlOptions: {
            			style: google.maps.NavigationControlStyle.SMALL
            		},
            		mapTypeId: google.maps.MapTypeId.ROADMAP
            	};

            	map = new google.maps.Map(document.getElementById("map_canvas"), settings);

            	var center;

            	function calculateCenter() {
            		center = map.getCenter();
            	}
            	google.maps.event.addDomListener(map, 'idle', function() {
            		calculateCenter();
            	});
            	google.maps.event.addDomListener(window, 'resize', function() {
            		map.setCenter(center);
            	});
            }

            function addMarker(point_lat, point_long, address, title) {

            	var point = new google.maps.LatLng(point_lat, point_long);
                var infowindow = new google.maps.InfoWindow();
                var formattedAddress = address.split(' ').join('+');

            	var contentString = '<div id="gmapContent" style="text-align:left; padding: 10px;">' +
            		'<a href="https://www.google.com/maps/dir/?api=1&destination=' + formattedAddress + '" target="_blank"><h3>' + title + '</h3></a>' +
            		'<div id="bodyContent"><p>' + address + '</p>' +
                '<p style="margin: 0 0 5px;"><a href="https://www.google.com/maps/dir/?api=1&destination=' + formattedAddress + '" target="_blank">' +
                '<i class="fa fa-external-link"></i> Get Directions</a></p></div></div>';

            	var companyMarker = new google.maps.Marker({
            		position: point,
            		map: map,
            		title: title
            	});

              infowindow.setContent(contentString);
              infowindow.open(map, companyMarker);

            }

            var locations = [];

            $('.location-map').each(function() {
            	var location = [];
            	location.push(parseFloat($(this).find('.map-data').find('.lat').data('lat')));
            	location.push(parseFloat($(this).find('.map-data').find('.lng').data('lng')));
            	location.push($(this).find('.map-data').find('.address').data('address'));
            	location.push($(this).find('.map-data').find('.title').data('title'));
            	locations.push(location);
            });

            if ($('#map_canvas').length) {
            	var bound = new google.maps.LatLngBounds();
            	for (i = 0; i < locations.length; i++) {
            		bound.extend(new google.maps.LatLng(locations[i][0], locations[i][1]));
            	}
            	initialize_gmap(bound);
            	for (i = 0; i < locations.length; i++) {
            		addMarker(locations[i][0], locations[i][1], locations[i][2], locations[i][3]);
            	}
            }
          };
          add_maps_script_tag();

      }
    },
    'has_filters': {
			init: function() {},
			finalize: function() {

				// change
				$('#search-filter-button').click(function(e) {
					e.preventDefault();

					// vars
					var url = window.location.pathname;
          if (url.indexOf('page/') >= 0) {
            url = url.substring(0, url.indexOf('page/'));
          }

					args = {};
					// loop over filters
					$('#archive-filters .filter').each(function() {
						// vars
						var filter = $(this).data('filter'),
							vals = [];

						// if statement finds checked inputs, as well as inputs that have class .minmax
						$(this).find('input').each(function() {
							if ($(this).prop('checked') || $(this).hasClass('minmax')) {
								vals.push($(this).val());
							}
						});
						//added this function in order to find option:selected as well.
						$(this).find('option:selected').each(function() {
							vals.push($(this).val());
						});
						//a REAL developer would know how to combine the two functions above.

						// append to args
						args[filter] = vals.join(',');
					});
					// update url
					url += '?';
					// loop over args
					$.each(args, function(name, value) {
						if (value !== '') {
							url += name + '=' + value + '&';
						}
					});
					// remove last &
					url = url.slice(0, -1);
					// reload page
					window.location.replace(url);
				});

				$('#clear-filter-button').click(function(e) {
					e.preventDefault();
					var url = window.location.pathname;

          if (url.indexOf('page/') >= 0) {
            url = url.substring(0, url.indexOf('page/'));
          }

					window.location.replace(url);
				});

			}
		},
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
